import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import { Button, Typography, useMediaQuery } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddIcon from "@mui/icons-material/Add";
import Advertisement from "./Advertisement";
import Explore from "./Explore";
import Textarea from "./Textarea";
import PromptIdeas from "./PromptIdeas";
import Navbar from "../../layouts/navbar/Index";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
    background: "none",
    boxShadow: "unset",
}));

export default function Index() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:899px)");
    const [showExplore, setShowExplore] = useState(false);
    const [textEditorVavlue, setTextEditorVavlue] = useState(null);
    const [apiloader, setApiLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setMsg] = useState(null);
    const handleToggle = () => {
        setShowExplore(!showExplore);
    };

    const handleClose = () => {
        setShowExplore(false);
    };
    const [showTextarea, setShowTextarea] = useState(false);

    const handleToggleTextarea = () => {
        setShowTextarea(true);
    };

    const handleClosePromptIdeas = () => {
        setShowTextarea(false);
    };
    const handleTextEditor = (event) => {
        setTextEditorVavlue(event.target.value);
    };
    const submitPropmtFun = () => {
      if(textEditorVavlue !== null){  
        setApiLoader(true);
        axios.post(process.env.REACT_APP_APIURL+'/api/text-to-video', {
          prompt: textEditorVavlue
        })
        .then(function (response) {          
          console.log("response+=====",response);
          if(response.status === 200 && response.data.status !== "error"){  
                setMsg(response.data.message);
                console.log("responseinerrr+=====",response.data);
                analyzeVideo(response);
          }else{
            setErrorMsg(response.data.message);
            setApiLoader(false);
            console.log("Error ++====", response.data.message);
            setState({ vertical: 'top', horizontal: 'center', open: true });
            setTimeout(function () {
               setState({...state, open: false });
            },8000);
            
          }
        })
        .catch(function (error) {
          setApiLoader(false);
          console.log(error);
        });
      }else{
        setApiLoader(false);
        setErrorMsg("Propmt is not available");
        setState({ vertical: 'top', horizontal: 'center', open: true });
        setTimeout(function () {
            setState({...state, open: false });
        },5000);
      }
    };

    const analyzeVideo = async(data) => {
        axios.post(process.env.REACT_APP_APIURL+'/api/analyze-video', {
            prompt: data.data.topic,
            video_urls:data.data.data
         }).then(function(response){
            console.log("res 2===", response);
            if(response.status === 200 && response.data.status !=='error'){
                setMsg(response.data.message);
                generateScript(response);
            }else{
                setErrorMsg(response.data.message);
                setApiLoader(false);
                setState({ vertical: 'top', horizontal: 'center', open: true });
                setTimeout(function () {
                    setState({...state, open: false });
                },8000);
            }
         });
    }

    const generateScript = async (responseData) => {
        axios.post(process.env.REACT_APP_APIURL+'/api/generate-script', {
            prompt: responseData.data.topic,
            downloadPaths:responseData.data.data
         }).then(function(response){
            console.log("res 3===", response);
            if(response.status === 200 && response.data.status !=='error'){
                setMsg(response.data.message);
                videoProcessing(response);
            }else{
                setErrorMsg(response.data.message);
                setApiLoader(false);
                setState({ vertical: 'top', horizontal: 'center', open: true });
                setTimeout(function () {
                    setState({...state, open: false });
                },8000);
            }
         });
    }

    const videoProcessing = async(scriptData) =>{
        axios.post(process.env.REACT_APP_APIURL+'/api/video-processing', {
            prompt: scriptData.data.topic,
            data:scriptData.data.data
        }).then(function(response){
            console.log("res 4===", response);
            if(response.status === 200 && response.data.status !=='error'){
                setMsg(response.data.message);
                videoRendering(response);
            }else{
                setErrorMsg(response.data.message);
                setApiLoader(false);
                setState({ vertical: 'top', horizontal: 'center', open: true });
                setTimeout(function () {
                    setState({...state, open: false });
                },8000);
            }
         });
    }

    const videoRendering = async(videoData) =>{
        axios.post(process.env.REACT_APP_APIURL+'/api/video-rendering', {
            source: videoData.data.data
        }).then(function(response){
            console.log("res 5===", response.data.data[0]);
            if(response.status === 200 && response.data.status !=='error'){
                setMsg(response.data.message);
                navigate('/video', { state: { data: response.data.data[0] } });
                setApiLoader(false);
            }else{
                setErrorMsg(response.data.message);
                setApiLoader(false);
                setState({ vertical: 'top', horizontal: 'center', open: true });
                setTimeout(function () {
                    setState({...state, open: false });
                },8000);
            }
         });
    }

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
      const { vertical, horizontal, open } = state;
       
     
    return (
        <Box>
            <Navbar />
            <Box sx={{ flexGrow: 1, mt: "41px" }}>
                <Container maxWidth="xl" sx={{ maxWidth: "1760px !important" }}>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleClose}
                        message={errorMsg}
                        key={vertical + horizontal}
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={apiloader}
                        //onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={0}>
                        <Grid sx={{ px: "40px" }} xs={8}>
                            <Item>
                                <Item sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: "62px",
                                            fontWeight: 700,
                                            lineHeight: "73px",
                                            color: "white",
                                        }}
                                    >
                                        Hello,
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: "62px",
                                            fontWeight: 700,
                                            lineHeight: "73px",
                                            background:
                                                "linear-gradient(95.83deg, #FFB500 23.09%, #9459FE 33.18%, #3F2DB0 40.94%)",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                        }}
                                    >
                                        Alex!
                                    </Typography>
                                </Item>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: "62px",
                                        fontWeight: 700,
                                        lineHeight: "73px",
                                        color: "white",
                                    }}
                                >
                                    How can I help you today?
                                </Typography>
                                {apiloader && 
                                    <div className="overlay">
                                        <div className="overlay__inner">
                                            <div className="overlay__content"><span className="spinner"></span></div>
                                        </div>
                                    </div>
                                }
                                <Item sx={{ mt: "27px" }}>
                                    {showTextarea ? (
                                        <PromptIdeas onClose={handleClosePromptIdeas} />
                                    ) : (
                                        <Textarea
                                            disabled={!apiloader}
                                            textEditorVavlue={textEditorVavlue}
                                            handleTextEditor={handleTextEditor}
                                        />
                                    )}
                                </Item>
                                <Grid
                                    sx={{
                                        mt: "32px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Item>
                                        {" "}
                                        <Button
                                            sx={{
                                                display: "flex",
                                                gap: "4px",
                                                background:
                                                    "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
                                                padding: "14px 20px",
                                                borderRadius: "25px",
                                                border: "0.5px solid #BF9BFF40x",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 700,
                                                "&:hover": {
                                                    background:
                                                        "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={handleToggleTextarea}
                                        >
                                            <AutoFixHighIcon
                                                sx={{ width: "20px", height: "20px" }}
                                            />
                                            Click for prompt ideas{" "}
                                        </Button>
                                    </Item>
                                    <Item>
                                        <Button
                                            className="nav-button"
                                            sx={{
                                                display: "flex",
                                                gap: "4px",
                                                padding: "13px 32px",
                                                borderRadius: "100px",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 700,
                                                boxShadow: "0px -7px 16.8px 0px #B04204 inset",
                                            }}
                                            variant="contained"
                                            onClick={!apiloader ? submitPropmtFun : undefined}
                                        >
                                            {(successMsg === null)? (!apiloader)? 'Generate Content' :'Generating...':successMsg}
                                            <AutoAwesomeIcon
                                                sx={{ width: "20px", height: "20px" }}
                                            />
                                        </Button>
                                    </Item>
                                </Grid>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                        background: "#100D20",
                                        borderRadius: "12px",
                                        padding: "16px 20px",
                                        mt: "46px",
                                    }}
                                >
                                    <Grid sx={{}}>
                                        <Item>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    lineHeight: "26px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                    textAlign: isMobile ? "center" : "",
                                                }}
                                            >
                                                I want to create:
                                            </Typography>
                                        </Item>
                                    </Grid>

                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            flexDirection: isMobile ? "column" : "row",
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            YouTube Explainer
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            Script to Video
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            YouTube Shorts
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "#201A46",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "#ffffff73",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            New Shorts{" "}
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "rgba(191, 155, 255, 0.25)",
                                                fontSize: "18px",
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                padding: "12px 22px",
                                                borderRadius: "53px",
                                                border: "0.5px solid #BF9BFF40",
                                                color: "white",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "0.5px solid #BF9BFF40",
                                                    // background: "#363636",
                                                },
                                            }}
                                            variant="outlined"
                                            onClick={handleToggle}
                                        >
                                            Explore all{" "}
                                            <AddIcon sx={{ height: "20px", width: "20px" }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid xs={4}>
                            {showExplore ? (
                                <Explore handleClose={handleClose} />
                            ) : (
                                <Advertisement />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
