import React from "react";

const Textarea = (props) => {
  const { handleTextEditor, textEditorVavlue } = props;
    return (
        <div>
            <textarea
                style={{
                    border: "2px solid",
                    borderImageSource: "linear-gradient(146.26deg, #5131CE 5.05%, #FFA228 91.38%)",
                    borderImageSlice: 1, // Ensures the border image is properly applied
                    borderRadius: "16px", // Adds rounded corners to the box
                    padding: "16px",
                }}
                className="text-area-style"
                autosize
                placeholder="Give me a topic, language and detailed instructions"
                onChange={(e)=>handleTextEditor(e)}
            >{textEditorVavlue}</textarea>
        </div>
    );
};

export default Textarea;
