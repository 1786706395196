import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box, TextField, Grid } from "@mui/material";
import ShortsImage from '../../assests/Popups/InstaShorts.png';
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase"; 
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ReactFlagsSelect from "react-flags-select";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: "54px",
    position: "relative",
    backgroundColor: "#100D2080",
    border: "0.5px solid #BF9BFF40",
    padding: "10px 26px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "white",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: 400,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: "54px",
      border: "0.5px solid #BF9BFF40",
      boxShadow: "none",
      backgroundColor: "#100D2080",
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  background: "none",
  boxShadow: "unset",
}));
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#40475A",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "white",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
            [`& .MuiInputBase-input`]: {
              color: "white !important",
              fontSize: "18px",
              lineHeight: "43px",
              fontWeight: 500,
              padding: "0px !important",
            },
            [`& ..MuiFormControl-root`]: {
              width: "75% !important",
              background: "red",
            },
          },
        },
      },
    },
  });
const modalStyle = {
  position: "absolute",
  width: "1059px",
  height: "810px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#110F2D",
  border: "1px solid #DFCDFF1A",
  borderRadius: "20px",
  p: 4,
  overflowY: "scroll",
};

export default function Index({ openYouTubeShorts, setOpenYouTubeShorts }) {
  const handleCloseYouTubeShorts = () => setOpenYouTubeShorts(false);

  const outerTheme = useTheme();

  const [age, setAge] = useState(1);
  const handleChangePace = (event) => {
    setAge(event.target.value);
  };

  
  const [radioChecked, setRadioChecked] = useState("female");
  const handleCheckRadio = (event) => {
    setRadioChecked(event.target.value);
  };

  const [selected, setSelected] = useState("");
  return (
    <div>
      <Modal
        className="youtube-pop-up"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openYouTubeShorts}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openYouTubeShorts}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src={ShortsImage} alt="Insta Shorts" style={{ width: "29px", height: "36px", marginRight:"10px" }} />
                <Typography
                  id="transition-modal-title"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "31px",
                    color: "white",
                  }}
                  variant="h6"
                  component="h2"
                >
                  Youtube Shorts
                </Typography>
              </Grid>

              <Grid
                onClick={handleCloseYouTubeShorts}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: "#201A46",
                  border: "0.5px solid #BF9BFF40",
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                }}
              >
                <CloseIcon sx={{ color: "white", fontSize: "20px" }} />
              </Grid>
            </Box>

            <Box className="youtube-accordion-parent">
              <Accordion defaultExpanded className="youtube-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "31px",
                      color: "white",
                    }}
                    variant="h6"
                  >
                    Basic Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ alignItems: "center" }} container spacing={0}>
                    <Grid xs={6}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Pace of video
                        </Typography>
                        <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                          <Select
                            className="duration-menu"
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={age}
                            onChange={handleChangePace}
                            input={<BootstrapInput />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: "rgba(16, 13, 32, 0.7)",
                                  color: "white",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "20px",
                                  marginTop: "5px",
                                  "& .MuiMenuItem-root": {
                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                    color: "white",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "20px",
                                    marginTop: "5px",
                                    "&:hover": {
                                      bgcolor: "rgba(44, 34, 102, 0.75)",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value={1}>Select video duration</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Item>
                    </Grid>
                    <Grid xs={6}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Language
                        </Typography>
                        <FormControl sx={{ width: "56%" }} variant="standard" className="youtube-dropdown-custom">
                          <Select
                            className="duration-menu"
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={age}
                            onChange={handleChangePace}
                            input={<BootstrapInput />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: "rgba(16, 13, 32, 0.7)",
                                  color: "white",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "20px",
                                  marginTop: "5px",
                                  "& .MuiMenuItem-root": {
                                    bgcolor: "rgba(16, 13, 32, 0.7)",
                                    color: "white",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "20px",
                                    marginTop: "5px",
                                    "&:hover": {
                                      bgcolor: "rgba(44, 34, 102, 0.75)",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value={1}>Language</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid sx={{ mt: "25px" }}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        color: "white",
                      }}
                      variant="h6"
                    >
                      Topic
                    </Typography>
                    <textarea
                      style={{
                        borderRadius: "18px",
                        padding: "16px 27px",
                        marginTop: "12px",
                      }}
                      className="text-area-popup"
                      autosize
                      placeholder="I want a Mars rover flying in the air and, there should be a robot looking at the rover and waving hands..."
                      // onChange={(e)=>handleTextEditor(e)}
                    ></textarea>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: "34px",
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        color: "white",
                      }}
                      variant="h6"
                    >
                      Background music
                    </Typography>
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "0.5px solid #BF9BFF40",
                        p: "13px 24px",
                        background: "#100D2080",
                        width: "75%",
                        borderRadius: "164px",
                      }}
                    >
                      <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                          sx={{ width: "100%" }}
                          placeholder="Dark and haunting, upbeat and happy etc..."
                        />
                      </ThemeProvider>
                    </Item>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="youtube-accordion"
                style={{ marginTop: "12px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel12-header"
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "31px",
                      color: "white",
                    }}
                    variant="h6"
                  >
                    Audience Engagement Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid xs={7}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Hook
                        </Typography>
                        <Item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "0.5px solid #BF9BFF40",
                            p: "13px 24px",
                            background: "#100D2080",
                            width: "75%",
                            borderRadius: "164px",
                          }}
                        >
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              sx={{ width: "100%" }}
                              placeholder="Compelling fact or statistic"
                            />
                          </ThemeProvider>
                        </Item>
                      </Item>
                    </Grid>
                    <Grid xs={5}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Audience
                        </Typography>
                        <Item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "0.5px solid #BF9BFF40",
                            p: "13px 24px",
                            background: "#100D2080",
                            borderRadius: "164px",
                          }}
                        >
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              sx={{ width: "100%" }}
                              placeholder="Who is this for?"
                            />
                          </ThemeProvider>
                        </Item>
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid sx={{ mt: "25px" }} container spacing={0}>
                    <Grid xs={6}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Tone
                        </Typography>
                        <Item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "0.5px solid #BF9BFF40",
                            p: "13px 24px",
                            background: "#100D2080",
                            width: "75%",
                            borderRadius: "164px",
                          }}
                        >
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              sx={{ width: "100%" }}
                              placeholder="Informative, thought-provoking, entertaining"
                            />
                          </ThemeProvider>
                        </Item>
                      </Item>
                    </Grid>
                    <Grid xs={6}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Call-to-action
                        </Typography>
                        <Item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "0.5px solid #BF9BFF40",
                            p: "13px 24px",
                            background: "#100D2080",
                            borderRadius: "164px",
                          }}
                        >
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              sx={{ width: "100%" }}
                              placeholder="Subscribe to my channel"
                            />
                          </ThemeProvider>
                        </Item>
                      </Item>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="youtube-accordion"
                style={{ marginTop: "12px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel13-header"
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "31px",
                      color: "white",
                    }}
                    variant="h6"
                  >
                    Audience Engagement Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid sx={{alignItems:"center"}} container spacing={0}>
                    <Grid xs={5}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Voice
                        </Typography>
                        <FormControl>
      <RadioGroup
      className="youtubeRadioBtn"
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        sx={{display:"flex", flexDirection:"row", gap:"7px"}}
        onChange={handleCheckRadio}
      >
        <FormControlLabel className="femaleBtn" value="female" control={<Radio sx={{color:"#763CDE"}}/>} label="Female" sx={{color:"rgba(255, 255, 255, 0.6)",
              border: "1px solid #763CDE #100D2080",
    padding: "10px 15px",
    borderRadius: "54px",
    background: radioChecked === "female" ? "#261e5b" : "#100D2080",
        }}/>
        <FormControlLabel className="maleBtn" value="male" control={<Radio sx={{color:"#763CDE"}}/>} label="Male" sx={{color:"rgba(255, 255, 255, 0.6)",
              border: "1px solid #763CDE",
    padding: "10px 15px",
    borderRadius: "54px",
    background: radioChecked === "male" ? "#261e5b" : "#100D2080",
        }}/>
      </RadioGroup>
    </FormControl>
                      </Item>
                    </Grid>
                    <Grid xs={7}>
                      <Item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          }}
                          variant="h6"
                        >
                          Accent
                        </Typography>
                        <ReactFlagsSelect
                        className="country-dropdown"
    selected={selected}
    onSelect={(code) => setSelected(code)}
  />;
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      marginTop: "34px",
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        color: "white",
                      }}
                      variant="h6"
                    >
                      Watermark text
                    </Typography>
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "0.5px solid #BF9BFF40",
                        p: "13px 24px",
                        background: "#100D2080",
                        width: "75%",
                        borderRadius: "164px",
                      }}
                    >
                      <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                          sx={{ width: "100%" }}
                          placeholder="Type your text here..."
                        />
                      </ThemeProvider>
                    </Item>
                    <Item>
                      <LogoutIcon
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          transform: "rotate(-90deg) !important",
                        }}
                      />
                    </Item>
                  </Grid>

                  <Grid
                    sx={{
                      marginTop: "34px",
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                      className="class-color"
                        style={{ margin: "0px" }}
                        control={<Checkbox defaultChecked />}
                        label="Include Generative Engine Optimization (GEO) content"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            color: "white",
                          },
                        }}
                      />
                    </FormGroup>
                    <Item>
                      <ErrorOutlineIcon
                        sx={{
                          color: "white",
                          fontSize: "20px",
                          opacity: "30%",
                        }}
                      />
                    </Item>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Item sx={{ display: "flex", justifyContent: "end", mt: "22px" }}>
                <Button
                  sx={{
                    background: "#763CDE",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 700,
                    padding: "15px 28px",
                    borderRadius: "9px",
                    boxShadow: "0px -7px 16.8px 0px #3C2496 inset",
                    color: "#ffffff",
                    textTransform: "none",
                    "&:hover": {
                      background: "#763CDE",
                    },
                  }}
                  variant="outlined"
                >
                  Continue{" "}
                  <ArrowRightAltIcon
                    sx={{ fontSize: "25px", color: "white", ml: "14px" }}
                  />
                </Button>
              </Item>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
