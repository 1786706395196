import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import TheatersIcon from '@mui/icons-material/Theaters';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ImageIcon from '@mui/icons-material/Image';
import MailIcon from '@mui/icons-material/Mail';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArticleIcon from '@mui/icons-material/Article';
import Youtubevideo from '../../Popups/YoutubeVideo/Index';
import YoutubeShorts from '../../Popups/YoutubeShorts/Index';
import TiktokVideo from '../../Popups/TiktokVideo/Index';
import InstagramReels from '../../Popups/InstagramReels/Index';
import CloseIcon from '@mui/icons-material/Close';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
const Item = styled(Paper)(({ theme }) => ({
  background:"none", boxShadow:"unset"
}));

const Explore = ({handleClose, }) => {
  const [openYouTube, setOpenYouTube] = React.useState(false);
  const handleOpenYouTube = () => setOpenYouTube(true);
 
  const [openYouTubeShorts, setOpenYouTubeShorts] = React.useState(false);
  const handleOpenYouTubeShorts = () => setOpenYouTubeShorts(true);

  const [openTiktokVideo, setOpenTiktokVideo] = React.useState(false);
  const handleOpenTiktokVideo = () => setOpenTiktokVideo(true);

  const [openInstagramReels, setOpenInstagramReels] = React.useState(false);
  const handleOpenInstagramReels = () => setOpenInstagramReels(true);
  return (
    <div>
       <Grid className='explore-component'>
          <Grid sx={{padding:"20px", background:" linear-gradient(0deg, rgba(16, 13, 32, 0.95), rgba(16, 13, 32, 0.95)), linear-gradient(0deg, rgba(16, 13, 32, 0.95), rgba(16, 13, 32, 0.95))", borderRadius:"20px"}}>
            <Grid sx={{display:"flex", justifyContent:"space-between", alignItems:"center", my:"20px"}}>
<Typography variant='h6' sx={{fontSize:"18px", fontWeight:700, lineHeight:"23px", color:"white"}}>
I want to create:
</Typography>
<Item>
  <CloseIcon onClick={handleClose} sx={{color:"white", fontSize:"20px", cursor:"pointer"}}/>
</Item>
            </Grid>
            <Item sx={{ padding:"20px 23px", background:"linear-gradient(0deg, rgba(81, 49, 206, 0.75) 15.16%, rgba(41, 25, 104, 0.75) 145.9%)",
              border:"1px solid #B48FF8BF", borderRadius:"13px"
            }}>
              <Item sx={{display:"flex", alignItems:"center", justifyContent:"center", gap:"15px"}}>
              <SaveAsIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Ideation
            </Typography>
            </Item>
            <Item sx={{mt:"12px", background:"#201A46", border:"0.5px solid #BF9BFF40", borderRadius:"10px", p:"9px"}}>
              <Typography sx={{fontSize:"18px", fontWeight:400, lineHeight:"23px", textAlign:"center", color:"white"}}>Brainstorm New Ideas</Typography>
            </Item>
            </Item>

            <Item sx={{mt:"20px", border: "1px solid #B48FF81A", background: "#1c1638 ", padding:"25px 20px", borderRadius:"13px"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px"}}>
              <TheatersIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Video
            </Typography>
            </Item>
            <Item sx={{mt:"13px", display:"flex", alignItems:"center", flexWrap:"wrap", gap:"9px"}}>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                      onClick={handleOpenYouTube}
                    >
                      YouTube Video{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                      onClick={handleOpenYouTubeShorts}
                    >
                      YouTube Shorts{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                      Facebook Ad{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                      onClick={handleOpenInstagramReels}
                    >
                      Instagram Reel{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                      onClick={handleOpenTiktokVideo}
                    >
                      Tik Tok Video{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                      Edit a Video{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                      Create Video From Blog or News Article{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                      Change Video Language{" "}
                    </Button>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                      Add Captions{" "}
                    </Button>
         
            </Item>
            </Item>
            <Item sx={{mt:"14px", display:"flex", justifyContent:"space-between", alignItems:"center",}}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <RecordVoiceOverIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Voiceovers
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New Voiceover{" "}
                    </Button>
            </Item>
        
        
            <Item sx={{padding:"20px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <ImageIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Image
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New Image{" "}
                    </Button>
            </Item>
            </Item>
           
           
           
            <Item sx={{mt:"14px", display:"flex", justifyContent:"space-between", alignItems:"center",}}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <InsertDriveFileIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Text Based Posts
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New Sound Effects{" "}
                    </Button>
            </Item>
        
        
            <Item sx={{padding:"20px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <ArticleIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Blog
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     White Blog Content{" "}
                    </Button>
            </Item>
            </Item>

                  
            <Item sx={{mt:"14px", display:"flex", justifyContent:"space-between", alignItems:"center",}}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <PictureAsPdfIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            PDF
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New PDF{" "}
                    </Button>
            </Item>
        
        
            <Item sx={{padding:"20px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <MailIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Email Newsletter
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                   Write Email Content{" "}
                    </Button>
            </Item>
            </Item>



            <Item sx={{mt:"14px", display:"flex", justifyContent:"space-between", alignItems:"center",}}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <MusicNoteIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Music
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New Music{" "}
                    </Button>
            </Item>
        
        
            <Item sx={{padding:"20px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <VolumeUpIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Sound Effects
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                   New Sound Effects{" "}
                    </Button>
            </Item>
            </Item>




            <Item sx={{mt:"14px", display:"flex", justifyContent:"space-between", alignItems:"center",}}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <ImportContactsOutlinedIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Presentations
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     New Powerpoint{" "}
                    </Button>
            </Item>
        
        
            <Item sx={{padding:"20px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px", width:"49%"}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <FeedOutlinedIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Script
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                   New Script{" "}
                    </Button>
            </Item>
            </Item>


            
            <Item sx={{mt:"14px", }}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px",}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <WidgetsOutlinedIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Bulk Create Content
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     Create a Variety of Content At Once{" "}
                    </Button>
            </Item>
            </Item>
            <Item sx={{mt:"14px", }}>
            <Item sx={{padding:"20px 22px", border: "1px solid #B48FF81A", background: "#1c1638", borderRadius:"13px",}}>
            <Item sx={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
              <TouchAppOutlinedIcon sx={{color:"white", fontSize:"22px"}}/>
            <Typography sx={{fontSize:"20px", lineHeight:"26px", fontWeight:700, color:"white"}}>
            Bulk Publish Content
            </Typography>
            </Item>
            <Button
                   sx={{
                    background: "transparent",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    padding: "9px 15px",
                    borderRadius: "9px",
                    border: "0.5px solid #BF9BFF80",
                    color: "#ffffff",
                    textTransform: "none",
                    opacity:"50%",
                    mt:"13px",
                    width:"100%",
                    "&:hover": {
                      border: "0.5px solid #BF9BFF40",
                      background: "transparent",
                    },
                  }}
                      variant="outlined"
                    >
                     Publish Content to Social media{" "}
                    </Button>
            </Item>
            </Item>



          </Grid>
          </Grid>
          {
            openYouTube &&

            <Youtubevideo handleOpenYouTube={handleOpenYouTube} openYouTube={openYouTube} setOpenYouTube={setOpenYouTube}/>
          }
          {
            openYouTubeShorts &&

            <YoutubeShorts handleOpenYouTubeShorts={handleOpenYouTubeShorts} openYouTubeShorts={openYouTubeShorts} setOpenYouTubeShorts={setOpenYouTubeShorts}/>
          }
          {
            openTiktokVideo &&

            <TiktokVideo handleOpenTiktokVideo={handleOpenTiktokVideo} openTiktokVideo={openTiktokVideo} setOpenTiktokVideo={setOpenTiktokVideo}/>
          }
          {
            openInstagramReels &&

            <InstagramReels handleOpenInstagramReels={handleOpenInstagramReels} openInstagramReels={openInstagramReels} setOpenInstagramReels={setOpenInstagramReels}/>
          }
    </div>
  )
}

export default Explore

