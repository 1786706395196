import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FreeMode, Pagination } from "swiper/modules";
import Navbar from "../../layouts/navbar/Index";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

const options = ["All", "Video", "Image"];
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#40475A",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "white",
              border: "1px solid #BF9BFF40",
              background: "#141338",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
            [`& .MuiInputBase-input`]: {
              color: "white !important",
              fontSize: "18px",
              lineHeight: "43px",
              fontWeight: 500,
              padding: "0px !important",
            },
            [`& ..MuiFormControl-root`]: {
              width: "75% !important",
              background: "red",
            },
          },
        },
      },
    },
  });
const Item = styled(Paper)(({ theme }) => ({
  background: "none",
  boxShadow: "unset",
}));

const Index = () => {
  const outerTheme = useTheme();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Navbar />
      <Box sx={{}}>
        <Container
          maxWidth="xl"
          sx={{
            maxWidth: "1660px !important",
            border: "1px solid #DFCDFF1A",
            borderRadius: "20px",
            background: "#100D20D9",
            mt: "40px",
            padding: "24px 22px",
            height: "85vh",
            overflowY: "scroll",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                sx={{
                  background: "#763CDE",
                  fontSize: "18px",
                  lineHeight: "23px",
                  fontWeight: 500,
                  padding: "14px 24px",
                  borderRadius: "69px",
                  border: "none",
                  color: "#FFFFFF",
                  textTransform: "none",
                  "&:hover": {
                    border: "none",
                    background: "#763CDE",
                  },
                }}
                variant="outlined"
              >
                <InsertPhotoIcon
                  sx={{ fontSize: "25px", color: "white", mr: "14px" }}
                />
                Edit Media{" "}
              </Button>

              <Button
                sx={{
                  background: "#763CDE1A",
                  fontSize: "18px",
                  lineHeight: "23px",
                  fontWeight: 500,
                  padding: "14px 24px",
                  borderRadius: "69px",
                  border: "1px solid #BF9BFF40",
                  color: "#FFFFFF",
                  textTransform: "none",
                  opacity: "60%",
                  "&:hover": {
                    border: "1px solid #BF9BFF40",
                    background: "#763CDE1A",
                  },
                }}
                variant="outlined"
              >
                <DescriptionIcon
                  sx={{ fontSize: "25px", color: "white", mr: "14px" }}
                />
                Edit Script{" "}
              </Button>
              <Button
                sx={{
                  background: "#763CDE1A",
                  fontSize: "18px",
                  lineHeight: "23px",
                  fontWeight: 500,
                  padding: "14px 24px",
                  borderRadius: "69px",
                  border: "1px solid #BF9BFF40",
                  color: "#FFFFFF",
                  textTransform: "none",
                  opacity: "60%",
                  "&:hover": {
                    border: "1px solid #BF9BFF40",
                    background: "#763CDE1A",
                  },
                }}
                variant="outlined"
              >
                <MusicNoteIcon
                  sx={{ fontSize: "25px", color: "white", mr: "14px" }}
                />
                Edit Music{" "}
              </Button>
            </Grid>
            <Link href to="/video" style={{ textDecoration: "none" }}>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: "#201A46",
                  border: "0.5px solid #BF9BFF40",
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                }}
              >
                <CloseIcon sx={{ color: "white", fontSize: "20px" }} />
              </Grid>
            </Link>
          </Grid>

          <Grid
            sx={{
              border: "2px solid #DFCDFF1A",
              background: "#141338",
              borderRadius: "20px",
              mt: "25px",
              padding: "12px 15px 12px 2px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "white",
                transform: "rotate(90deg)",
                opacity: "60%",
              }}
              variant="body1"
            >
              Chapter1
            </Typography>

            <Box sx={{ flexGrow: 1 }} className="media-slider">
              <Swiper
                slidesPerView={5}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Item
                    sx={{
                      background: "#2E2756",
                      borderRadius: "10px",
                      height: "180px",
                      width: "260px",
                    }}
                  >
                    {" "}
                  </Item>
                </SwiperSlide>
              </Swiper>

            </Box>
          </Grid>

          <Grid
            sx={{
              border: "2px solid #DFCDFF1A",
              background: "#100D20",
              borderRadius: "20px",
              mt: "20px",
              padding: "26px 37px",
            }}
          >
            <Item sx={{display:"flex", alignItems:"center", gap:"5px"}}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "26px",
                color: "white",
                opacity: "30%",
              }}
              variant="body1"
            >
              Chapter 1
            </Typography>
            <ChevronRightIcon sx={{color:"white", opacity:"30%"}}/>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "26px",
                color: "white",
                opacity: "30%",
              }}
              variant="body1"
            >
             Media 2
            </Typography>
            </Item>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "white",
                opacity: "60%",
                mt: "18px",
              }}
              variant="body1"
            >
              Lorem ipsum dolor sit amet,{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#FFE9B3",
                  mt: "18px",
                }}
              >
                consectetur adipiscing elit,
              </span>{" "}
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu.
            </Typography>
          </Grid>

          <Grid
            sx={{
              border: "2px solid #DFCDFF1A",
              background: "rgba(20, 19, 56, 0.6)",
              borderRadius: "20px",
              mt: "20px",
              padding: "20px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Item>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    color: "white",
                  }}
                  variant="body1"
                >
                  My Media
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "white",
                    opacity: "60%",
                    mt: "5px",
                  }}
                  variant="body1"
                >
                  Select media to replace
                </Typography>
              </Item>

              <Item
                className="media-searchbtn"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  justifyContent: "end",
                }}
              >
                <Button
                  sx={{
                    background: "#141338",
                    fontSize: "18px",
                    lineHeight: "23px",
                    fontWeight: 500,
                    padding: "14px 24px",
                    borderRadius: "69px",
                    border: "1px solid #BF9BFF40",
                    color: "#FFFFFF",
                    textTransform: "none",
                    "&:hover": {
                      border: "1px solid #BF9BFF40",
                      background: "#141338",
                    },
                  }}
                  variant="outlined"
                >
                  <CloudUploadIcon
                    sx={{ fontSize: "25px", color: "white", mr: "14px" }}
                  />
                  Upload Media{" "}
                </Button>
                <Box
                  sx={{
                    border: "1px solid #BF9BFF40",
                    background: "#141338",
                    padding: "12px 0px 12px 12px",
                    borderRadius: "25px",
                  }}
                >
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "white" }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ButtonGroup
                              variant="contained"
                              ref={anchorRef}
                              aria-label="Button group with a nested menu"
                            >
                              <Button
                                size="small"
                                aria-controls={
                                  open ? "split-button-menu" : undefined
                                }
                                aria-expanded={open ? "true" : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                sx={{
                                  borderRadius: "0",
                                  background: "transparent",
                                  textTransform: "none",
                                  opacity: "40%",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  lineHeight: "23px",
                                  borderLeft: "1px solid white",
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                                onClick={handleToggle}
                              >
                                {options[selectedIndex]}
                                <KeyboardArrowDownIcon
                                  sx={{ color: "white" }}
                                />
                              </Button>
                            </ButtonGroup>
                            <Popper
                              sx={{
                                zIndex: 1,
                                minWidth: "150px",
                              }}
                              open={open}
                              anchorEl={anchorRef.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                    background: "#0e0f10",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleClose}
                                    >
                                      <MenuList
                                        id="split-button-menu"
                                        autoFocusItem
                                      >
                                        {options.map((option, index) => (
                                          <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) =>
                                              handleMenuItemClick(event, index)
                                            }
                                            sx={{
                                              color: "white",
                                              "&:hover": {
                                                background: "#763CDE",
                                                borderRadius: "20px",
                                              },
                                            }}
                                          >
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ color: "white" }}
                    />
                  </ThemeProvider>
                </Box>
              </Item>
            </Grid>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "23px",
                color: "white",
                opacity: "40%",
                mt: "32px",
              }}
              variant="body1"
            >
              Recently Added
            </Typography>
            <Box sx={{ flexGrow: 1, mt: "12px" }}>
              <Grid container spacing={0}>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
                <Grid xs={6} sm={4} md={3} lg={1.5} sx={{ padding: "10px" }}>
                  <Item
                    sx={{
                      border: "1.96px solid #763CDE",
                      borderRadius: "10px",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Item
                      sx={{
                        background: "#2E2756",
                        borderRadius: "10px",
                        margin: "auto",
                        height: "180px",
                        width: "175px",
                      }}
                    ></Item>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            sx={{
              mt: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "20px",
            }}
          >
            <Button
              sx={{
                background: "transparent",
                fontSize: "18px",
                lineHeight: "23px",
                fontWeight: 400,
                padding: "14px 24px",
                borderRadius: "69px",
                border: "1px solid #BF9BFF40",
                color: "#FFFFFF",
                textTransform: "none",
                opacity: "60%",
                "&:hover": {
                  border: "1px solid #BF9BFF40",
                  background: "transparent",
                },
              }}
              variant="outlined"
            >
              Discard{" "}
            </Button>

            <Button
              sx={{
                background: "rgba(118, 60, 222, 0.25)",
                fontSize: "18px",
                lineHeight: "23px",
                fontWeight: 500,
                padding: "14px 24px",
                borderRadius: "69px",
                border: "1px solid #BF9BFF40",
                color: "#FFFFFF",
                textTransform: "none",
                opacity: "60%",
                "&:hover": {
                  border: "1px solid #BF9BFF40",
                  background: "rgba(118, 60, 222, 0.25)",
                },
              }}
              variant="outlined"
            >
              <CheckIcon
                sx={{ fontSize: "25px", color: "white", mr: "14px" }}
              />
              Apply Changes{" "}
            </Button>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Index;
