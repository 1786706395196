import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import DehazeIcon from "@mui/icons-material/Dehaze";
// import WhiteLogo from "../../assests/Logo/nav-img/white-logo.png";
import Logo from "../../assests/Logo/nav-img/main-logo.png";
import Sidebar from "../../layouts/Sidebar/Index";
import { Typography } from "@mui/material";
import CrownWhite from "../../assests/Logo/nav-img/UnionWhite.png";
import Profile from "../../assests/Logo/nav-img/ProfileNew.png";
import { Link } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  background: "none",
  boxShadow: "none",
}));

export default function Index() {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
 <Sidebar/>
  );
  return (
    <Box sx={{height:"76px"}}>
    <Box sx={{position:"fixed", width:"100%", top:"0px", left:"0px"}}>
    <Box
      sx={{ flexGrow: 1, background:"#100D20", borderRadius:"14px" }}
    >
      <Grid container sx={{padding:"10px 0px"}} spacing={0}>
        <Grid sx={{ display: "flex", alignItems: "center" }} xs={6}>
          <Grid
            onClick={toggleDrawer(true)}
            sx={{cursor:"pointer", "&:hover": { background: "unset" } }}
          >
            <Item sx={{ padding: "14px 20px" }}>
              <DehazeIcon
                sx={{ height: "24px", width: "24px", color: "white" }}
              />
            </Item>
          </Grid>
          <Item
            sx={{
              width: "1px",
              height: "32px",
              background: "rgba(255, 255, 255, 0.1)",
              marginRight: "20px",
            }}
          >
            {" "}
          </Item>
          <Item sx={{ display: "flex" }}>
            <Link to="/">
            <img
              alt="Logo"
              src={Logo}
              style={{
                width: "145px",
                height: "50px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
            </Link>
          </Item>
        </Grid>
        <Grid
          sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap:"49px" }}
          xs={6}
        >
        
          <Item
            sx={{
              padding: "14px 18px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: "14px",
              border: "0.5px solid #BF9BFF40",
              borderRadius: "9px",
              background:"linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)",
              "&:hover": { background: "linear-gradient(157.34deg, rgba(118, 60, 222, 0.35) -0.9%, rgba(63, 45, 176, 0.35) 85.27%)" },
            }}
          >
            <Item sx={{ display: "flex" }}>
              <img
                alt="Crown"
                src={CrownWhite}
                style={{ width: "20px", height: "20px" }}
              />
            </Item>
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "white",
                lineHeight: "20px",
              }}
            >
              Director’s Cut
            </Typography>
          </Item>
          <Item
            sx={{
              margin: "0px 24px 0px 20px",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border:"1px solid #FFFFFF4D"
            }}
          >
            <img
              alt="Profile"
              src={Profile}
              style={{
                width: "41px",
                height: "41px",
                borderRadius: "50px",
                background: "#363636",
                cursor: "pointer",
                margin:"3px"
              }}
            />
          </Item>
        </Grid>
      </Grid>
      <Grid className="nav-drawer" open={open} >
        {DrawerList}
      </Grid>
    </Box>
    </Box>
    </Box>
  );
}
